<template>
  <div>
    <link rel="preload" href="../assets/paperbg.jpg" as="image" />
    <div class="constitution-bg" @click="closeModal"></div>
    <div id="constitution">
      <header>
        <h3>Constitution</h3>
        <p>
          The following is NJIT ACM's official constitution. Our constitution
          defines the rules and regulations of our ACM chapter and is upheld by
          the NJIT Student Senate. As stated in our constitution, we also abide
          by the
          <a
            href="https://www.acm.org/about-acm/acm-code-of-ethics-and-professional-conduct"
            target="_blank"
            >National ACM Code of Ethics and Professional Conduct</a
          >.
        </p>
      </header>
      <div>
        <div>
          <h5>Article I. Name</h5>
          <ul>
            <li>
              The name of this organization shall be the Association for
              Computing Machinery (ACM). In reference to the ACM when in the
              context of the international organization, the name shall be the
              New Jersey Institute of Technology Chapter of the Association for
              Computing Machinery (NJIT ACM).
            </li>
          </ul>
        </div>
        <div>
          <h5>Article II. Purpose</h5>
          <ul>
            <li>
              The purpose of this chapter is to organize NJIT students with
              interests in computing and the associated technologies, so that
              they may be able to share their knowledge and skills with their
              peers. This student collaboration in the studies of computing
              technologies will result in a variety of events, projects, and
              services hosted by this chapter.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article III. Non-Discrimination Policy</h5>
          <ul>
            <li>
              Membership in this chapter shall be open to any student currently
              enrolled at NJIT.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article IV. Membership</h5>
          <ul>
            <li>
              Section 1. Membership in this chapter shall be open to any student
              currently enrolled at NJIT.
            </li>
            <li>
              Section 2. An active member shall be defined as any member who has
              attended at least one-half of all general body meetings at which
              attendance has been taken up to that point in the semester.
            </li>
            <li>
              Section 3. Only NJIT full-time undergraduate students who are
              active members as described in Article IV, Section 2 shall be
              eligible to vote.
            </li>
            <li>
              Section 4. Attendance by a simple majority, or greater than 50%,
              of the active voting membership shall constitute a quorum.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article V. Officers & Elections</h5>
          <ul>
            <li>
              Section 1. The officers of this chapter shall be President,
              Vice-President, Secretary, Treasurer, Webmaster, Public Relations,
              SIG Master, and Graphic Designer.
            </li>
            <li>
              Section 2. Election of officers shall be by secret ballot during
              the second to last meeting of the Fall semester. Officers shall be
              elected by a majority vote of approval of a quorum.
            </li>
            <li>
              Section 3. Installation of newly-elected officers shall be held
              upon the start of the Spring semester. The term of the newly
              elected officers shall be for one calendar year.
            </li>
            <li>
              Section 4. All NJIT full-time undergraduate students who are
              active members of this chapter are eligible to run. However,
              students who will graduate before the fall semester of the
              academic year following elections shall be ineligible for office.
              Their graduation date should be no less than two full Spring
              and/or Fall semesters after the elections. Additionally, a student
              will only be eligible if their grade point average for the
              preceding quarter is 2.5 or above.
              <ul></ul>
            </li>
            <li>
              Section 5. Any officer shall be brought up for removal from office
              if his grade point average falls below the minimum standards set
              forth in Article V, Section 4, or if the members feel he or she is
              not adequately performing their duties. A new officer shall be
              elected to fill the vacancy at the next scheduled meeting. An
              officer may be removed from office by a three-fourths majority
              vote of a quorum.
            </li>
            <li>
              Section 6. No elected officer of this chapter shall have veto
              power.
            </li>
            <li>
              Section 7. This chapter shall elect a faculty advisor from the
              faculty/staff of New Jersey Institute of Technology with the
              approval of the Office of the Dean of Student Services. The
              faculty advisor shall be selected by a majority vote of approval
              of a quorum.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article VI. Duties of Officers</h5>
          <ul>
            <li>
              Section 1. The President shall preside at all meetings of this
              chapter and of its Executive Council. He or she shall also
              represent this chapter at all meetings with the ACM Regional
              Representative. They shall also appoint all committees of this
              chapter and committee chairmen. They shall delegate officer duties
              not mentioned in this Constitution as needed. He or she shall also
              maintain steady contact with the College of Computing Sciences.
            </li>
            <li>
              Section 2. The Vice-President shall assume the duties of the
              President in the event of the President's absence. They shall
              assume those duties of the President that are delegated to them by
              the President. They shall serve as this chapter's liaison to the
              Student Senate.
            </li>
            <li>
              Section 3. The Secretary shall keep minutes of all chapter
              meetings. It is their duty to make this information publicly
              available. They shall also be responsible for submitting any
              pertinent paperwork to ACM National and/or the NJIT Student Senate
              in a timely fashion. They shall also be responsible for the active
              membership roster according to Article IV of this constitution.
            </li>
            <li>
              Section 4. The Treasurer shall maintain the chapter's financial
              operations. They shall also make the annual report of the chapter
              finances as required by the Treasurer of ACM National.
            </li>
            <li>
              Section 5. The Webmaster shall be responsible for maintaining and
              updating the chapter website. They shall also be responsible for
              managing permissions and access of the officers to the various
              services utilized by the organization. They shall be responsible
              for maintaining any custom applications created for the
              organization, only if the application creator has no intent of
              maintaining it.
            </li>
            <li>
              Section 6. The Public Relations officer shall be responsible for
              maintaining this chapter's public image. Their duties shall be,
              but are not limited to, ensuring that this chapter's events are
              well advertised, recruiting new students into the organization,
              and handling the chapter’s social media.
            </li>
            <li>
              Section 7. The SIG Master shall be responsible for the management
              of all Special Interest Groups. They shall be the point of contact
              for SIG leaders to act as an advisor and assistant, and will
              continue regular communication with SIG leaders. They shall also
              be responsible for recruiting SIG leaders.
            </li>
            <li>
              Section 8. The Graphic Designer shall be responsible for designing
              promotional materials for the chapter. Their duties include, but
              are not limited to, designing flyers for events of the chapter, as
              well as theme art and t-shirts for the chapter.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article VII. Meetings</h5>
          <ul>
            <li>
              Meetings shall be held every week during the Friday common hour.
              Members will be notified prior to any change in the meeting time
              or location.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article VIII. Conduct</h5>
          <ul>
            <li>
              Section 1. This chapter and its members agree to uphold and abide
              by the rules and regulations of New Jersey Institute of
              Technology.
            </li>
            <li>
              Section 2. This chapter and its members agree to uphold and abide
              by the standard ethical guidelines outlined in the National ACM
              Code of Ethics and Professional Conduct.
            </li>
            <li>
              Section 3. The chapter acknowledges that it is responsible for the
              behavior of members and guests at any of its functions.
            </li>
            <li>
              Section 4. Hazing in any form is not allowed by this organization
              or its individual members.
            </li>
          </ul>
        </div>
        <div>
          <h5>Article IX. Amendments and Procedure</h5>
          <ul>
            <li>
              Section 1. Proposed amendments to this constitution shall be
              distributed to all current officers and members in a timely
              manner.
            </li>
            <li>
              Section 2. An amendment to this constitution shall be officially
              approved once unanimously agreed upon by all current officers,
              having received a favorable 2/3 vote from a quorum.
            </li>
            <li>
              Section 3. Robert's Rules of Order Revised shall be the final
              authority on any points not covered in the National ACM
              Constitution or in this Constitution.
            </li>
          </ul>
        </div>
      </div>
      <!-- <footer>
        <button class="close-button" @click="closeModal">Close X</button>
      </footer> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstitutionView",
  emits: ["close-modal"],
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
  // mounted() {
  //   document.body.style.backgroundImage = "url('paperbg.jpg')";
  // },
  // unmounted() {
  //   document.body.style.backgroundImage = "";
  // },
};
</script>

<style scoped>
#constitution {
  background-image: url("../assets/paperbg.jpg");

  width: 97.5vw;
  padding: 2rem;
  margin: 1rem auto;
  border-radius: 16px;
  z-index: 1;
  /* background-image: url("../assets/paperbg.jpg"); */
}
.constTitle {
  margin-top: 1% !important;
}
/* .sect {
  margin: 0 5% 3% 5%;
}
.sect p {
  margin: 1% 1% 1% 3%;
}
.sect ul {
  margin: 1%;
} */
h3 {
  font-size: 4.8rem;
  text-align: center;
}
h5 {
  text-align: center;
  font-size: 3.6rem;
}
ul {
  width: 90%;
  margin: 0 auto;
}
p,
li {
  font-size: 3rem;
  margin: 1.5rem 4rem;
}
header {
  text-align: center;
}
/* footer {
  position: relative;
} */
button {
  position: relative;
  right: 50rem;
}
</style>
  